@font-face {
  font-family: 'ES Klarheit Kurrent TRIAL';
  src: url('assets/fonts/ESKlarheitKurrent-Smbd-Trial.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'ES Klarheit Kurrent TRIAL';
  src: url('assets/fonts/ESKlarheitKurrent-Xbd-Trial.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/Inter-VariableFont_opsz\,wght.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  font-family: 'ES Klarheit Kurrent TRIAL';
}

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: none;
}

html,
body {
  max-width: 100vw;
  overflow: visible;
  text-rendering: geometricPrecision;
  scroll-behavior: smooth;
}

a,
a:link,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

aside,
nav,
footer,
header,
section,
main {
  display: block;
}

ul,
ul {
  list-style: none;
}

address {
  font-style: normal;
}

button:hover,
button:focus {
  cursor: pointer;
}

.loader {
  top: 1rem;
  position: absolute;
  width: 30px;
  align-self: center;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #727f93 90%, #f0f1f3);
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  20% {
    background-position:
      0% 0%,
      50% 50%,
      100% 50%;
  }
  40% {
    background-position:
      0% 100%,
      50% 0%,
      100% 50%;
  }
  60% {
    background-position:
      0% 50%,
      50% 100%,
      100% 0%;
  }
  80% {
    background-position:
      0% 50%,
      50% 50%,
      100% 100%;
  }
}
