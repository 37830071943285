.editor {
  box-sizing: border-box;
  cursor: text;
  background-color: #F6F7F8;
  font-weight: 400;
  font-size: 0.875rem;
  color: #142237;
  padding: 16.500px 14px;

  height: 100%;
  width: 100%;
}

.editor :global(.public-DraftEditor-content) {
  height: 100%;
  overflow: hidden;
}

.editor :global(.DraftEditor-root) {
  height: 100%;
}

/* .editor :global(.public-DraftStyleDefault-block > span) {
  display: inline-block;
} */