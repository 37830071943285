.mention {
    color: #575f67;
    cursor: pointer;
    display: inline-block;
    background: #e6f3ff;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 2px;
    -webkit-text-decoration: none;
    text-decoration: none;
}

.mentionSuggestions {
    position: absolute;
    z-index: 2001;
    border-top: 1px solid #eee;
    background: #fff;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    box-shadow: 0px 4px 30px 0px rgba(220, 220, 220, 1);
    transform-origin: 50% 0%;
    transform: scaleY(0);

    min-width: 220px;
    max-height: 250px;
    overflow-y: auto;
}

.mentionSuggestionsEntryContainer {
    display: table;
    width: 100%;
}

.mentionSuggestionsEntryContainerLeft,
.mentionSuggestionsEntryContainerRight {
    display: table-cell;
    vertical-align: middle;
}

.mentionSuggestionsEntryContainerRight {
    width: 100%;
    padding-left: 8px;
}

.mentionSuggestionsEntry {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    padding: 7px 10px 3px 10px;
    transition: background-color 0.4s cubic-bezier(.27, 1.27, .48, .56);
}

.mentionSuggestionsEntry:active {
    background-color: #cce7ff;
}

.mentionSuggestionsEntryFocused {
    composes: mentionSuggestionsEntry;
    background-color: #e6f3ff;
}

.mentionSuggestionsEntryText,
.mentionSuggestionsEntryTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mentionSuggestionsEntryText {
    font-size: 0.9em;
}

.mentionSuggestionsEntryTitle {
    font-size: 80%;
    color: #a7a7a7;
}

.mentionSuggestionsEntryAvatar {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
}